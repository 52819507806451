import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Legal Notice</h2>
          <p>
          THE INDIVIDUAL PROVIDING ASSISTANCE TO YOU UNDER THIS CONTACT IS NOT AN ATTORNEY LICENSED TO PRACTICE LAW OR ACCREDITED BY THE BOARD OF IMMIGRATION APPEALS TO PROVIDE REPRESENTATION TO YOU BEFORE THE BUREAU OF CITIZENSHIP AND IMMIGRATION SERVICES, THE DEPARTMENT OF LABOR, THE DEPARTMENT OF STATE OR ANY IMMIGRATION AUTHORITIES AND MAY NOT GIVE LEGAL ADVICE OR ACCEPT FEES FOR LEGAL ADVICE” “YOU MAY CANCEL ANY CONTRACT WITHIN 3 BUSINESS DAYS AND GET BACK YOUR DOCUMENTS AND ANY MONEY YOU PAID.” “THE INDIVIDUAL PROVIDING ASSISTANCE TO YOU IS NOT AN ATTORNEY LICENSED TO PRACTICE LAW OR ACCREDITED BY THE BOARD OF IMMIGRATION APPEALS TO PROVIDE REPRESENTATION TO YOU BEFORE THE BUREAU OF CITIZENSHIP AND IMMIGRATION SERVICES, THE DEPARTMENT OF LABOR, THE DEPARTMENT OF STATE OR ANY IMMIGRATION AUTHORITIES AND MAY NOT GIVE LEGAL ADVICE OR ACCEPT FEES FOR LEGAL ADVICE
          </p>
        </div>
        <div id="row">
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
